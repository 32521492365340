import styled from "styled-components";

export const ButtonWithDropDownWrapper = styled.div`
  @media (min-width: 992px) {
    margin-right: 30px;
  }
  @media (max-width: 576px) {
    button {
      width: 100%;
    }
  }
`;

export const StoreListWrapper = styled.div`
  top: 30px;
  display: flex;
  flex-direction: column;
  width: 260px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 7px 26px 0 rgba(10, 31, 68, 0.06);
  z-index: 1000;
`;

export const LogoImage = styled.img`
  margin-right: 8px;
`;

export const StoreListItem = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.36;
  color: #263242;

  &:nth-child(2) {
    padding: 23px 18px 10px 18px;
  }

  &:last-child {
    padding: 10px 18px 22px 18px;
  }
`;
