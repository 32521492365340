import styled from "styled-components";
import { Link } from "gatsby";

import { buttonStyles } from "./buttonStyles";

const ButtonLink = styled(Link)`
  text-decoration: none;
  ${buttonStyles}
`;

ButtonLink.defaultProps = {
  variant: "blue",
};

export default ButtonLink;
