import React, { ReactElement, ReactNode, useEffect, useState } from "react";

import { ButtonVariant } from "../../types";
import Button from "./Button";
import { anchor, ToggleLayer } from "react-laag";
import ResizeObserver from "resize-observer-polyfill";

import StoreList from "./StoreList";
import { ButtonWithDropDownWrapper } from "./styled";

interface ButtonWithDropDownProps {
  children: ReactNode;
  className?: string;
  variant?: ButtonVariant;
}

const ButtonWithDropDown = ({
  children,
  variant,
  className,
}: ButtonWithDropDownProps): ReactElement => {
  const [togglePlacement, setTogglePlacement] = useState<
    "BOTTOM_CENTER" | "TOP_CENTER"
  >("BOTTOM_CENTER");
  const changeTogglePlacement = () => {
    if (window.innerWidth > 767) {
      setTogglePlacement("BOTTOM_CENTER");
    } else {
      setTogglePlacement("TOP_CENTER");
    }
  };
  useEffect(() => {
    changeTogglePlacement();
    window.addEventListener("resize", changeTogglePlacement);
    return () => {
      window.removeEventListener("resize", changeTogglePlacement);
    };
  }, []);
  return (
    <ButtonWithDropDownWrapper className={className}>
      <ToggleLayer
        ResizeObserver={ResizeObserver}
        closeOnOutsideClick
        closeOnDisappear="full"
        placement={{ anchor: anchor[togglePlacement], triggerOffset: 12 }}
        renderLayer={({ layerProps, arrowStyle, isOpen }) =>
          isOpen && (
            <StoreList
              listRef={layerProps.ref}
              arrowStyle={arrowStyle}
              style={{
                ...layerProps.style,
              }}
            />
          )
        }
      >
        {({ toggle, triggerRef }) => (
          <Button ref={triggerRef} onClick={toggle} variant={variant}>
            {children}
          </Button>
        )}
      </ToggleLayer>
    </ButtonWithDropDownWrapper>
  );
};

export default ButtonWithDropDown;
