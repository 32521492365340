import React, { CSSProperties, ReactElement, Ref } from "react";

import appStoreIcon from "../../assets/ico-appstore-24.svg";
import playMarketIcon from "../../assets/ico-playmarket-24.svg";

import { LogoImage, StoreListItem, StoreListWrapper } from "./styled";

interface StoreListProps {
  listRef: Ref<HTMLDivElement>;
  style: CSSProperties;
  arrowStyle: CSSProperties;
}

interface ArrowProps {
  color: string;
  style: CSSProperties;
}

export const Arrow = ({ style, color }: ArrowProps): ReactElement => (
  <svg width={14} height={7} style={style}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M7 .07v1.428l-5.55 5.5L0 6.982zM7 .07v1.428l5.55 5.5L14 6.982z"
      />
      <path fill={color} d="M1.45 7L7 1.498 12.55 7z" />
    </g>
  </svg>
);

const StoreList = ({
  listRef,
  style,
  arrowStyle,
}: StoreListProps): ReactElement => {
  return (
    <StoreListWrapper ref={listRef} style={style}>
      <Arrow
        color="#fff"
        style={{
          ...arrowStyle,
          position: "absolute",
          transformOrigin: "center",
        }}
      />
      <StoreListItem
        href="https://apps.apple.com/de/app/id1521872083"
        target="_blank"
      >
        <LogoImage src={appStoreIcon} alt="App Store" /> App Store
      </StoreListItem>
      <StoreListItem
        href="https://play.google.com/store/apps/details?id=com.stromee.android"
        target="_blank"
      >
        <LogoImage src={playMarketIcon} alt="Google Play" /> Google Play
      </StoreListItem>
    </StoreListWrapper>
  );
};

export default StoreList;
