import styled from "styled-components";

import { buttonStyles } from "./buttonStyles";

const Button = styled.button`
  ${buttonStyles}
`;

Button.defaultProps = {
  variant: "blue",
};

export default Button;
