import { css } from "styled-components";
import { CSSProperties } from "react";
import { ButtonVariant } from "../../types";

interface ButtonProps {
  styles?: CSSProperties;
  variant?: ButtonVariant;
}

export const buttonStyles = css<ButtonProps>`
  position: relative;
  cursor: pointer;
  padding: 20px 20px;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  border-radius: 30px;
  border: none;
  outline: none;
  transition: all 0.15s ease-in-out;
  ${({ variant }) =>
    variant === "orange" &&
    `
      color: #263242;
      background-color: #fce33f;
    `}
    
  ${({ variant }) =>
    variant === "grayBorder" &&
    `
      color: #263242;
      background-color: #ffffff;
    `}  

  ${({ variant }) =>
    variant === "blue" &&
    `
      color: #fff;
      background-color: #526df2;
    `}
  ${({ variant }) =>
    variant === "white" &&
    `
      color: #263242;
      background-color: #ffffff;
    `}
  &:hover {
    ${({ variant }) =>
      variant === "orange" &&
      `
      background-color: #fff051;
    `}

    ${({ variant }) =>
      variant === "blue" &&
      `
      background-color: #54a0ff;
    `}
    ${({ variant }) =>
      variant === "white" &&
      `
      background-color: #fff051;
    `}
    ${({ variant }) =>
      variant === "grayBorder" &&
      `
      border: 2px solid #526df2;
      background-color: #ffffff;
    `}
  }

  &:active {
    ${({ variant }) =>
      variant === "orange" &&
      `
      background-color: #ffd105;
    `}

    ${({ variant }) =>
      variant === "blue" &&
      `
      background-color: #435de1;
    `}
  }

  &:disabled {
    ${({ variant }) =>
      variant === "orange" &&
      `
      color: #fff;
      background-color: rgba(252, 227, 63, 0.5);
    `}

    ${({ variant }) =>
      variant === "blue" &&
      `
      background-color: rgba(82, 109, 242, 0.5);
    `}
  }
  ${(props) => props.styles && { ...props.styles }}
`;
